import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils'

let defaultState = {
    loading: true,
    alertData: [],
    // this is needed because often the new data is displayed together with the old data where the new data is being transitioned in and the old data is being removed 
    // shownAlerts: [],
    error: null,
    lastRefreshed: null,
    cursor: null,
    alertCount: 0,
    nextPage: 1,
    isSorted: false,
}

export const highCostAtom = atomWithReset(defaultState);
export const highCostAlertsAtom = atomWithReset(defaultState);
export const clarificationsAtom = atomWithReset(defaultState);
export const priorAuthAtom = atomWithReset(defaultState);
export const nonCoveredAtom = atomWithReset(defaultState);
export const therapeuticIntAtom = atomWithReset(defaultState);
export const admissionMedsAtom = atomWithReset(defaultState);
export const patMissingAtom = atomWithReset(defaultState);
// TODO: Rename to refillTooSoonAtom after pumpPickup
export const pharmacyAlertsAtom = atomWithReset(defaultState);
export const pumpPickupAtom = atomWithReset(defaultState);
export const narcoticsRefillAtom = atomWithReset(defaultState);

export const highCostAtomFilteredCount = atomWithReset(null);
export const highCostAlertsAtomFilteredCount = atomWithReset(null);
export const clarificationsAtomFilteredCount = atomWithReset(null);
export const priorAuthAtomFilteredCount = atomWithReset(null);
export const nonCoveredAtomFilteredCount = atomWithReset(null);
export const therapeuticIntAtomFilteredCount = atomWithReset(null);
export const admissionMedsAtomFilteredCount = atomWithReset(null);
export const patMissingAtomFilteredCount = atomWithReset(null);
// TODO: pharmacyAlertsAtomFilteredCount is not being used
// remove after pumpPickup
export const pharmacyAlertsAtomFilteredCount = atomWithReset(null);

export const highCostAtomFiltered = atomWithReset([]);
export const highCostAlertsAtomFiltered = atomWithReset([]);
export const clarificationsAtomFiltered = atomWithReset([]);
export const priorAuthAtomFiltered = atomWithReset([]);
export const nonCoveredAtomFiltered = atomWithReset([]);
export const therapeuticIntAtomFiltered = atomWithReset([]);
export const admissionMedsAtomFiltered = atomWithReset([]);
export const patMissingAtomFiltered = atomWithReset([]);
// TODO: pharmacyAlertsAtomFilteredCount is not being used
// remove this and refferences after pumpPickup
export const pharmacyAlertsAtomFiltered = atomWithReset([]);

export const highCostAtomShownAlerts = atomWithReset([]);
export const highCostAlertsAtomShownAlerts = atomWithReset([]);
export const clarificationsAtomShownAlerts = atomWithReset([]);
export const priorAuthAtomShownAlerts = atomWithReset([]);
export const nonCoveredAtomShownAlerts = atomWithReset([]);
export const therapeuticIntAtomShownAlerts = atomWithReset([]);
export const admissionMedsAtomShownAlerts = atomWithReset([]);
export const patMissingAtomShownAlerts = atomWithReset([]);
// TODO: pharmacyAlertsAtomFilteredCount is not being used
// remove this and refferences after pumpPickup
export const pharmacyAlertsAtomShownAlerts = atomWithReset([]);

export const highCostAtomUserSettings = atomWithReset({ sortOrder: 1, hidden: false });
export const highCostAlertsAtomUserSettings = atomWithReset({ sortOrder: 1, hidden: false });
export const clarificationsAtomUserSettings = atomWithReset({ sortOrder: 2, hidden: false });
export const priorAuthAtomUserSettings = atomWithReset({ sortOrder: 3, hidden: false });
export const nonCoveredAtomUserSettings = atomWithReset({ sortOrder: 4, hidden: false });
export const therapeuticIntAtomUserSettings = atomWithReset({ sortOrder: 5, hidden: false });
export const admissionMedsAtomUserSettings = atomWithReset({ sortOrder: 6, hidden: false });
export const patMissingAtomUserSettings = atomWithReset({ sortOrder: 7, hidden: false });
// TODO: pharmacyAlertsAtomFilteredCount is not being used
// replace with refillTooSooAtomUserSettings after pumpPickup
export const pharmacyAlertsAtomUserSettings = atomWithReset({ sortOrder: 8, hidden: false });
export const refillTooSooAtomUserSettings = atomWithReset({ sortOrder: 9, hidden: false });
export const pumpPickupAtomUserSettings = atomWithReset({ sortOrder: 9, hidden: false });
export const narcoticsRefillAtomUserSettings = atomWithReset({ sortOrder: 9, hidden: false });

// export const highCostAtomFacilitySettings = atomWithReset({ sortOrder: 1, hidden: false });
// export const clarificationsAtomFacilitySettings = atomWithReset({ sortOrder: 2, hidden: false });
// export const priorAuthAtomFacilitySettings = atomWithReset({ sortOrder: 3, hidden: false });
// export const nonCoveredAtomFacilitySettings = atomWithReset({ sortOrder: 4, hidden: false });
// export const therapeuticIntAtomFacilitySettings = atomWithReset({ sortOrder: 5, hidden: false });
// export const admissionMedsAtomFacilitySettings = atomWithReset({ sortOrder: 6, hidden: false });
// export const patMissingAtomFacilitySettings = atomWithReset({ sortOrder: 7, hidden: false });