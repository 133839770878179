import jsPDF from "jspdf";
import "jspdf-autotable";
// Date Fns is used to format the dates we receive
// from our API call
import { format } from "date-fns";

// define a generatePDF function that accepts a tickets argument
export const generatePDF = (data) => {
    // initialize jsPDF
    const doc = new jsPDF();

    // define the columns we want and their titles
    const tableColumn = ["RX Number", "Drug", "Dispense Date", "Days Supply", "Qty", "Total Price"];
    // define an empty array of rows
    const tableRows = [];

    // for each ticket pass all its data into an array
    data.admissionMedsDetailsList.forEach(row => {
        const ticketData = [
            row.rxNo,
            row.drug,
            new Date(row.dispenseDt).toLocaleDateString(),
            row.daysSupply,
            row.qty,
            '$' + row.ttlPrice,
            // called date-fns to format the date on the ticket
        ];
        // push each tickcet's info into a row
        tableRows.push(ticketData);
    });

    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    // startY is basically margin-top
    doc.setFont('Helvetica')
    doc.autoTable(tableColumn, tableRows, { startY: 78, headStyles: { fillColor: '#452f69' } });
    const date = Date().split(" ");
    // we use a date string to generate our filename.
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
    // ticket title. and margin-top + margin-left
    doc.setFontSize(25);
    doc.text('Admission / Medication Review', pageWidth / 2, 20, { align: 'center' });
    doc.setLineWidth(0.5)
    doc.line(14, 25, pageWidth - 14, 25)
    doc.setFontSize(15);
    // center text
    // doc.text('Admission Date: ' + new Date(data.admDate).toLocaleDateString(), pageWidth / 2, 35, {align: 'center'});
    // doc.text('Patient Name: ' + data.patName, pageWidth / 2, 45,{align: 'center'});
    // doc.text('Facility Name: ' + data.facName, pageWidth / 2, 55,{align: 'center'});
    // start from left
    doc.text('Admission Date: ' + new Date(data.admDate).toLocaleDateString(), 14, 35);
    doc.text('Patient Name: ' + data.patName, 14, 45);
    doc.text('Facility Name: ' + data.facName, 14, 55);
    doc.text('Nursing Station: ' + data.nsid + '  Room: ' + data.room + '  Bed: ' + data.bed, 14, 65);
    doc.setLineWidth(0.2)
    doc.line(14, 68, pageWidth - 14, 68)
    doc.setFontSize(13);
    doc.text('Total Meds: ' + data.admissionMedsDetailsList.length + '  Grand Total: $' + data.sumTtlPrice, pageWidth - 14, 75, { align: 'right' });
    // we define the name of our PDF file.
    // doc.autoPrint();
    // window.open(doc.output('bloburl'), '_blank');   
    return doc
};

export const printPumpPickupList = (data) => {
    const doc = new jsPDF();

    // define the columns we want and their titles
    const tableColumn = ["Patient", "Facility", "Nursing Station", "Serial Number", "Requested Date"];
    // define an empty array of rows
    const tableRows = [];

    data.forEach(row => {
        const ticketData = [
            row.patientName,
            row.facilityName,
            row.nursingStation,
            row.serialNumber,
            new Date(row.receivedOn).toLocaleDateString(),
        ];
        // push each tickcet's info into a row
        tableRows.push(ticketData);
    });

    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    // startY is basically margin-top
    doc.setFontSize(25);
    doc.text('Pump Pickup Requests', pageWidth / 2, 20, { align: 'center' });
    doc.setLineWidth(0.5)
    doc.line(14, 25, pageWidth - 14, 25)
    doc.setFont('Helvetica')
    doc.autoTable(tableColumn, tableRows, { startY: 35, headStyles: { fillColor: '#452f69' } });
    return doc
};

export const printNarcoticsRefillList = (data) => {
    const doc = new jsPDF();

    // define the columns we want and their titles
    const tableColumn = ["Patient", "Facility", "Drug", "Prescriber Name", "Alerted On"];
    // define an empty array of rows
    const tableRows = [];

    data.forEach(row => {
        const ticketData = [
            row.patientName,
            row.facilityName,
            row.drugLabelName,
            row.physicianName,
            new Date(row.receivedOn).toLocaleDateString(),
        ];
        // push each tickcet's info into a row
        tableRows.push(ticketData);
    });

    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    // startY is basically margin-top
    doc.setFontSize(25);
    doc.text('Narcotics Refill Alerts', pageWidth / 2, 20, { align: 'center' });
    doc.setLineWidth(0.5)
    doc.line(14, 25, pageWidth - 14, 25)
    doc.setFont('Helvetica')
    doc.autoTable(tableColumn, tableRows, { startY: 35, headStyles: { fillColor: '#452f69' } });
    return doc
};

export const printNarcoticsRefillTicket = (data) => {
    const doc = new jsPDF();

    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    doc.setFontSize(22);
    doc.text('Narcotics Refill Ticket', pageWidth / 2, 20, { align: 'center' });
    doc.setLineWidth(0.5)
    doc.line(14, 25, pageWidth - 14, 25)
    doc.setFont('Helvetica')
    doc.setFontSize(15);
    doc.text('Patient Name: ' + data.patientName, 14, 35, { align: 'left' });
    doc.text('Rx Number: ' + data.rxNumber, 14, 43, { align: 'left' });
    doc.text('Drug Name: ' + data.drugLabelName, 14, 51, { align: 'left' });
    doc.line(14, 55, pageWidth - 14, 55)
    doc.text('Facility Name: ' + data.facilityName, 14, 65, { align: 'left' });
    doc.text('Prescriber Name: ' + data.physicianName, 14, 73, { align: 'left' });
    doc.text('Nursing Station: ' + data.nursingStation, 14, 81, { align: 'left' });
    doc.text('Directions: ' + data.sig, 14, 89, { align: 'left' });
    doc.line(14, 92, pageWidth - 14, 92);
    doc.text('Quantity: ' + data.qty, 14, 102, { align: 'left' });
    doc.text('Days Supply: ' + data.daysSupply, 14, 110, { align: 'left' });
    doc.text('Qty Remaining: ' + (data.totalQtyPrescribed - (data.fills * data.qty)), 14, 118, { align: 'left' });
    doc.text('Total Qty Prescribed: ' + data.totalQtyPrescribed, 14, 126, { align: 'left' });
    doc.line(14, 129, pageWidth - 14, 129);
    return doc
};

export default generatePDF;