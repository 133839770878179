import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, history } from 'react-router';
import { atom, useAtom } from 'jotai';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardTitle,
    Badge,
    CustomInput,
    CardText,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Container,
    FormGroup,
    Label,
    InputGroup,
    Input,
    InputGroupAddon,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    Form,
    FormFeedback,
    CardFooter,
} from 'reactstrap';
import classnames from 'classnames';
import { Icon } from '@iconify/react';
import cardAccountDetailsOutline from '@iconify/icons-mdi/card-account-details-outline';
import messageBadgeOutline from '@iconify/icons-mdi/message-badge-outline';
import accountSettingsOutline from '@iconify/icons-mdi/account-settings-outline';
import pencilIcon from '@iconify/icons-mdi/pencil';
import contentSave from '@iconify/icons-mdi/content-save';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import { SrxApi } from '../api/SrxApi';
import { UserContext } from '../contexts/UserContext';
import checkCircle from '@iconify/icons-mdi/check-circle';
import checkBold from '@iconify/icons-mdi/check-bold';
import { confirm } from '../utility-components/ConfirmModal';
import Draggable from 'react-draggable';
import cloneDeep from 'lodash.clonedeep';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    useSortable,
    arrayMove,
    arraySwap,
    rectSwappingStrategy,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { highCostAtomSortOrder } from '../jotai-atoms/AlertAtoms';
import Swal from 'sweetalert2';
import {
    highCostAtomUserSettings,
    highCostAlertsAtomUserSettings,
    clarificationsAtomUserSettings,
    priorAuthAtomUserSettings,
    nonCoveredAtomUserSettings,
    therapeuticIntAtomUserSettings,
    admissionMedsAtomUserSettings,
    refillTooSooAtomUserSettings,
    pumpPickupAtomUserSettings,
    narcoticsRefillAtomUserSettings,
} from '../jotai-atoms/AlertAtoms';

import { GridContextProvider, GridDropZone, GridItem, swap, move } from 'react-grid-dnd';
import { WidgetsData } from '../data/WidgetsData';
import { includes } from 'lodash';
// import FacilitySettingsPage from './FacilitySettings';

const Settings = () => {
    const [activeTab, setActiveTab] = useState('account');
    const { user } = useContext(UserContext);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        // navigate to /settings/account by default
        if (location.pathname === '/settings') history.replace('/settings/account');
    }, [location.pathname]);

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const navigateSettings = (e, category) => {
        e.preventDefault();
        history.push('/settings/' + category);
    };

    return (
        <>
            <Card style={{ height: '75vh', minWidth: 'max-content' }} className="mt-2">
                <Row className="h-100 d-flex flex-nowrap">
                    <Col sm={'auto'} className="pr-0 h-100">
                        <Card style={{ minWidth: '300px', width: '300px' }} className="mb-0 shadow h-100">
                            <ListGroup>
                                <ListGroupItem
                                    tag="a"
                                    href="#"
                                    action
                                    className={classnames({ active: activeTab === 'account' })}
                                    onClick={(e) => navigateSettings(e, 'account')}>
                                    <div className="w-100 h-100 d-flex">
                                        <div className="h-100">
                                            <Icon
                                                icon={cardAccountDetailsOutline}
                                                color={activeTab === 'account' ? '#f8f9fa' : '#A2A2A2'}
                                                width="22"
                                                height="22"
                                            />
                                        </div>
                                        <div className="ml-1">
                                            <div>
                                                <strong>Account</strong>
                                            </div>
                                            <small className={activeTab === 'account' ? 'text-light' : 'text-muted'}>
                                                View and update your account details
                                            </small>
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem
                                    tag="a"
                                    href="#"
                                    action
                                    className={classnames({ active: activeTab === 'specialtyRxNow' })}
                                    onClick={(e) => navigateSettings(e, 'specialtyRxNow')}>
                                    <div className="w-100 h-100 d-flex">
                                        <div className="h-100">
                                            <Icon
                                                icon={messageBadgeOutline}
                                                color={activeTab === 'specialtyRxNow' ? '#f8f9fa' : '#A2A2A2'}
                                                width="22"
                                                height="22"
                                                hFlip={true}
                                            />
                                        </div>
                                        <div className="ml-1">
                                            <div>
                                                <strong>SpecialtyRx Now</strong>
                                                {!!user.mediprocityUser && (
                                                    <Badge color="success" className="text-white ml-1 pt-1">
                                                        Verified
                                                    </Badge>
                                                )}
                                            </div>
                                            <small
                                                className={
                                                    activeTab === 'specialtyRxNow' ? 'text-light' : 'text-muted'
                                                }>
                                                Manage your SpecialtyRx Now credentials
                                            </small>
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem
                                    tag="a"
                                    href="#"
                                    action
                                    className={classnames({ active: activeTab === 'userSettings' })}
                                    onClick={(e) => navigateSettings(e, 'userSettings')}>
                                    <div className="w-100 h-100 d-flex">
                                        <div className="h-100">
                                            <Icon
                                                icon={accountSettingsOutline}
                                                color={activeTab === 'userSettings' ? '#f8f9fa' : '#A2A2A2'}
                                                width="22"
                                                height="22"
                                                hFlip={true}
                                            />
                                        </div>
                                        <div className="ml-1">
                                            <div>
                                                <strong>User Settings</strong>
                                            </div>
                                            <small
                                                className={activeTab === 'userSettings' ? 'text-light' : 'text-muted'}>
                                                Configure User Settings
                                            </small>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col style={{ flex: 1 }} className="mt-3 ml-4 mr-5 mb-3">
                        <Card
                            className="shadow h-100 w-100"
                            style={{
                                borderRadius: '14px',
                                minWidth: 'max-content',
                                padding: '20px 35px 20px 35px',
                                overflow: 'auto',
                            }}>
                            <Switch>
                                <Route path="/settings/account">
                                    <Account toggle={toggle} />
                                </Route>
                                <Route path="/settings/specialtyRxNow">
                                    <MediprocitySignUp toggle={toggle} />
                                </Route>
                                <Route path="/settings/userSettings">
                                    <UserSettings toggle={toggle} />
                                </Route>
                                {/* <Route path="/settings/facilitySettings">
                                    <FacilitySettingsPage />
                                </Route> */}
                                {/* <Route path='/omni/settings/files'>
                                <Files toggle={toggle} />
                            </Route>
                            <Route path='/omni/settings/webhooks'>
                                <Webhooks toggle={toggle} />
                            </Route>
                            <Route path='/omni/settings/tax'>
                                <Tax toggle={toggle} />
                            </Route> */}
                            </Switch>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

const Account = (props) => {
    const { user, setUser } = useContext(UserContext);
    console.log(user);

    const [accountInfo, setAccountInfo] = useState({
        displayName: user.displayName,
        email: user.email,
        phone: user.phone,
        betaUser: user.betaUser,
        title: user.userTitle,
    });

    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);

    const [disableSwitch, setDisableSwitch] = useState(false);

    function toggleResetPasswordModal() {
        setIsResetPasswordModalOpen(!isResetPasswordModalOpen);
    }

    const [enableEditFields, setEnableEditFields] = useState({
        displayName: false,
        phone: false,
        title: false,
    });

    const { toggle } = props;

    useEffect(() => {
        toggle('account');
    }, []);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function editUser(field) {
        // if(field === 'phone' && accountInfo.phone.replace(/\D/g,'').length < 10)
        //     return toast.error('Invalid Phone Number')
        if (field === 'betaUser') {
            setDisableSwitch(true);
            let payload = {
                email: user.email,
                title: user.userTitle,
                [field]: !accountInfo[field],
            };

            SrxApi.post('/edituserinfo', payload)
                .then((res) => {
                    toast.success(
                        res.data.userInfo.betaUser
                            ? `Activated ${res.data.userInfo.email} as Beta User`
                            : `Deactivated ${res.data.userInfo.email} as Beta User`
                    );
                    setAccountInfo({
                        ...accountInfo,
                        betaUser: res.data.userInfo.betaUser,
                    });
                })
                .catch((err) => toast.error('Error: ' + err.message))
                .finally(() => setDisableSwitch(false));
        } else if (enableEditFields[field]) {
            console.log(user.userTitle);
            let payload = {
                email: user.email,
                title: user.userTitle,
                [field]: field === 'phone' ? accountInfo[field].replace(/\D/g, '') : accountInfo[field],
            };

            SrxApi.post('/edituserinfo', payload)
                .then((res) => {
                    toast.success((field === 'displayName' ? 'User name' : 'User phone') + ' updated');
                    setUser(res.data.userInfo);
                    setEnableEditFields({
                        ...enableEditFields,
                        [field]: false,
                    });
                    console.log(res.data.userInfo);
                })
                .catch((err) => toast.error('Error: ' + err.message));
        } else {
            setEnableEditFields({
                ...enableEditFields,
                [field]: true,
            });
        }
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = match[1] ? '+1 ' : '';
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return phoneNumberString;
    }

    return (
        <>
            <Container
                className="mt-5 mb-2 w-50 px-5 py-3 border border-light shadow-sm"
                style={{ borderRadius: '14px', minWidth: 'max-content' }}>
                <h3>Account Info</h3>
                <hr />
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>Email:</Label>
                            <p style={{ fontSize: '18px', whiteSpace: 'nowrap' }}>
                                <b>
                                    <i>{accountInfo.email}</i>
                                </b>
                            </p>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="border-top mb-2" style={{ height: '1px', width: '250px' }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {enableEditFields.displayName ? (
                            <FormGroup>
                                <Label for="displayName">Name:</Label>
                                <InputGroup style={{ width: '250px' }}>
                                    <Input
                                        type="text"
                                        name="displayName"
                                        id="displayName"
                                        value={accountInfo.displayName}
                                        onChange={(e) =>
                                            setAccountInfo({
                                                ...accountInfo,
                                                displayName: e.target.value,
                                            })
                                        }
                                        disabled={!enableEditFields.displayName}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <span
                                            className="btn btn-secondary input-group-text py-0 px-1"
                                            onClick={() => editUser('displayName')}>
                                            <Icon
                                                icon={!enableEditFields.displayName ? pencilIcon : contentSave}
                                                width="25"
                                                height="25"
                                            />
                                        </span>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        ) : (
                            <FormGroup className="edit-account-field">
                                <Label for="displayName">
                                    Name:
                                    <Button
                                        color="link"
                                        className="ml-1 p-0"
                                        onClick={() =>
                                            setEnableEditFields({
                                                ...enableEditFields,
                                                displayName: true,
                                            })
                                        }>
                                        edit
                                    </Button>
                                </Label>
                                <p style={{ fontSize: '18px', whiteSpace: 'nowrap' }}>
                                    <b>{accountInfo.displayName}</b>
                                </p>
                            </FormGroup>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="border-top mb-2" style={{ height: '1px', width: '250px' }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {enableEditFields.phone ? (
                            <FormGroup>
                                <Label for="phone">Phone:</Label>
                                <InputGroup style={{ width: '250px' }}>
                                    <MaskedInput
                                        name="phone"
                                        value={accountInfo.phone}
                                        onChange={(e) =>
                                            setAccountInfo({
                                                ...accountInfo,
                                                phone: e.target.value,
                                            })
                                        }
                                        disabled={!enableEditFields.phone}
                                        mask={[
                                            '(',
                                            /[1-9]/,
                                            /\d/,
                                            /\d/,
                                            ')',
                                            ' ',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            '-',
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                        ]}
                                        placeholder="(xxx) xxxx-xxxx"
                                        className="form-control"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <span
                                            className="btn btn-secondary input-group-text py-0 px-1"
                                            onClick={() => editUser('phone')}>
                                            <Icon
                                                icon={!enableEditFields.phone ? pencilIcon : contentSave}
                                                width="25"
                                                height="25"
                                            />
                                        </span>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        ) : (
                            <FormGroup className="edit-account-field">
                                <Label for="phone">
                                    Phone:
                                    <Button
                                        color="link"
                                        className="ml-1 p-0"
                                        onClick={() =>
                                            setEnableEditFields({
                                                ...enableEditFields,
                                                phone: true,
                                            })
                                        }>
                                        edit
                                    </Button>
                                </Label>
                                <p style={{ fontSize: '18px', whiteSpace: 'nowrap' }}>
                                    <b>{formatPhoneNumber(accountInfo.phone.replace(/\D/g, ''))}</b>
                                </p>
                            </FormGroup>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="border-top mb-2" style={{ height: '1px', width: '250px' }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup className="d-flex mb-1">
                            <Label style={{ whiteSpace: 'nowrap' }}>Beta User:</Label>
                            <div className="ml-2">
                                <CustomInput
                                    type="switch"
                                    id="beta-user"
                                    name="beta-user"
                                    checked={accountInfo.betaUser}
                                    onChange={() => editUser('betaUser')}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="border-top mb-2" style={{ height: '1px', width: '250px' }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label>Password:</Label>
                            <p>
                                <Button color="link" className="px-0" onClick={toggleResetPasswordModal}>
                                    Reset Password
                                </Button>
                            </p>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {enableEditFields.title ? (
                            <FormGroup>
                                <Label for="title">Title:</Label>
                                <InputGroup style={{ width: '250px' }}>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="title"
                                        value={accountInfo.title}
                                        onChange={(e) =>
                                            setAccountInfo({
                                                ...accountInfo,
                                                title: e.target.value,
                                            })
                                        }
                                        disabled={!enableEditFields.title}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <span
                                            className="btn btn-secondary input-group-text py-0 px-1"
                                            onClick={() => editUser('title')}>
                                            <Icon
                                                icon={!enableEditFields.title ? pencilIcon : contentSave}
                                                width="25"
                                                height="25"
                                            />
                                        </span>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        ) : (
                            <FormGroup className="edit-account-field">
                                <Label for="title">
                                    Title:
                                    <Button
                                        color="link"
                                        className="ml-1 p-0"
                                        onClick={() =>
                                            setEnableEditFields({
                                                ...enableEditFields,
                                                title: true,
                                            })
                                        }>
                                        edit
                                    </Button>
                                </Label>
                                <p style={{ fontSize: '18px', whiteSpace: 'nowrap' }}>
                                    <b>{accountInfo.title}</b>
                                </p>
                            </FormGroup>
                        )}
                    </Col>
                </Row>
                <ResetPasswordModal isOpen={isResetPasswordModalOpen} toggle={toggleResetPasswordModal} user={user} />
            </Container>
        </>
    );
};

const UserSettings = (props) => {
    const { user, logoutUser } = useContext(UserContext);

    const [highCostUserSettings, setHighCostUserSettings] = useAtom(highCostAtomUserSettings);
    const [highCostAlertsUserSettings, setHighCostAlertsUserSettings] = useAtom(highCostAlertsAtomUserSettings);
    const [nonCoveredUserSettings, setNonCoveredUserSettings] = useAtom(nonCoveredAtomUserSettings);
    const [clarificationsUserSettings, setClarificationsUserSettings] = useAtom(clarificationsAtomUserSettings);
    const [priorAuthUserSettings, setPriorAuthUserSettings] = useAtom(priorAuthAtomUserSettings);
    const [therapeuticIntUserSettings, setTherapeuticIntUserSettings] = useAtom(therapeuticIntAtomUserSettings);
    const [admissionMedsUserSettings, setAdmissionMedsUserSettings] = useAtom(admissionMedsAtomUserSettings);
    const [refillTooSooUserSettings, setRefillTooSoonUserSettings] = useAtom(refillTooSooAtomUserSettings);
    const [pumpPickupUserSettings, setPumpPickupUserSettings] = useAtom(pumpPickupAtomUserSettings);
    const [narcoticsRefillUserSettings, setNarcoticsRefillUserSettings] = useAtom(narcoticsRefillAtomUserSettings);
    let history = useHistory();
    let alertTables = [
        {
            index: highCostUserSettings.sortOrder,
            id: 1,
            name: 'AUTHORIZATION',
            value: 'highCost',
            hidden: highCostUserSettings.hidden,
        },
        {
            index: clarificationsUserSettings.sortOrder,
            id: 2,
            name: 'CLARIFICATIONS',
            value: 'openBatch',
            hidden: clarificationsUserSettings.hidden,
        },
        {
            index: nonCoveredUserSettings.sortOrder,
            id: 3,
            name: 'NON-COVERED',
            value: 'nonCovered',
            hidden: nonCoveredUserSettings.hidden,
        },
        {
            index: priorAuthUserSettings.sortOrder,
            id: 4,
            name: 'PRIOR AUTHORIZATION MEDICATION',
            value: 'priorAuth',
            hidden: priorAuthUserSettings.hidden,
        },
        {
            index: therapeuticIntUserSettings.sortOrder,
            id: 5,
            name: 'THERAPEUTIC INTERCHANGED',
            value: 'theraInt',
            hidden: therapeuticIntUserSettings.hidden,
        },
        {
            index: admissionMedsUserSettings.sortOrder,
            id: 6,
            name: 'ADMISSION / MEDICATION REVIEW',
            value: 'admissionMeds',
            hidden: admissionMedsUserSettings.hidden,
        },
        {
            index: highCostAlertsUserSettings.sortOrder,
            id: 7,
            name: 'HIGH COST ALERTS',
            value: 'highCostAlerts',
            hidden: highCostAlertsUserSettings.hidden,
        },
        // refill too soon
        {
            index: refillTooSooUserSettings.sortOrder,
            id: 8,
            name: 'REFILL TOO SOON',
            value: 'refillTooSoon',
            hidden: refillTooSooUserSettings.hidden,
           // hidden: 
        },
        {
            index: pumpPickupUserSettings.sortOrder,
            id: 9,
            name: 'PUMP PICKUP',
            value: 'pumpPickup',
            hidden: pumpPickupUserSettings.hidden,
        },
        {
            index: narcoticsRefillUserSettings.sortOrder,
            id: 9,
            name: 'NARCOTICS REFILL',
            value: 'narcoticsRefill',
            hidden: narcoticsRefillUserSettings.hidden,
        },
        // {
        // id: 8,
        //     name: ' patMissing',
        //     value: 'patMissing',
        //     hidden: highCostAtomUserSettings.hidden
        // },
    ];

    const { toggle } = props;
    const [localData, setLocalData] = useState(alertTables);
    console.log(alertTables);
    useEffect(() => {
        toggle('userSettings');
    }, []);

    const handleSwitches = (e, item) => {
        let tempData = [...localData];
        tempData[tempData.findIndex((a) => a.value === item.value)].hidden =
            !tempData[tempData.findIndex((a) => a.value === item.value)].hidden;
        setLocalData([...tempData]);
    };

    const SortableUser = ({ user }) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: user.id });
        const style = {
            transition,
            transform: CSS.Transform.toString(transform),
        };

        return (
            <div
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
                className="user bg-light mb-2 p-1 text-center">
                <div>{user.name}</div>
            </div>
        );
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id === over.id) {
            return;
        }
        setLocalData((localData) => {
            const oldIndex = localData.findIndex((user) => user.id === active.id);
            const newIndex = localData.findIndex((user) => user.id === over.id);
            return arrayMove(localData, oldIndex, newIndex);
        });
    };

    const saveAlertConfig = () => {
        let settingValues = {};

        const setSetting = (alert, index) => {
            let widgetSort = `widget_${alert.value}_SortOrder`;
            let widgetShow = `widget_${alert.value}_Show`;

            settingValues = {
                ...settingValues,
                [widgetSort]: index.toString(),
                [widgetShow]: `${!alert.hidden}`,
            };
        };

        localData.forEach((alert, index) => {
            setSetting(alert, index);
        });

        let newobj = {
            UserId: user.userId,
            settingValues,
        };

        SrxApi.post('/usersetting', newobj)
            .then((res) => {
                console.log(res);
                toast.success(res.data);
                Swal.fire({
                    title: 'Logout and log back in to apply changes?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Logout and apply changes',
                    denyButtonText: `Apply changes later`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire('changes will be applied when you log back in!', '', 'success');
                        logoutUser();
                    } else if (result.isDenied) {
                        Swal.fire('Changes will be applied next time you login', '', 'info');
                    }
                });
            })
            .catch((err) => toast.error('Error: ' + err.message));
    };
    alertTables = alertTables.sort((a, b) => (a.index < b.index ? -1 : b.index < a.index ? 1 : 0));

    return (
        <>
            <Container
                className="mt-5 mb-2 w-100 px-5 py-3 border border-light shadow-sm"
                style={{ borderRadius: '14px', minWidth: 'max-content' }}>
                <div className="d-flex justify-content-center">
                    <h3 className="text-center">Alerts dashboard configuration</h3>
                </div>
                <hr />
                {user?.userSettings?.allowToSetSettings === 'true' ? (
                    <>
                        <Row>
                            <Col xs={6}>
                                <div>
                                    <Form>
                                        <FormGroup>
                                            <div>
                                                {alertTables?.map((item, index) => (
                                                    <div className="mb-3">
                                                        <CustomInput
                                                            key={Math.random()}
                                                            type="switch"
                                                            id={'exampleCustomSwitch' + index}
                                                            name={item.value}
                                                            value={localData.find((i) => i.value === item.value)}
                                                            label={item.name}
                                                            checked={
                                                                !localData.find((i) => i.value === item.value)?.hidden
                                                            }
                                                            onChange={(e) => handleSwitches(e, item)}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </Col>
                            <Col xs={6} className="text-right float-right">
                                <div>
                                    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                        <SortableContext
                                            items={localData}
                                            strategy={rectSortingStrategy}
                                            reorderItems={arraySwap}
                                            getNewIndex={({ id, items, activeIndex, overIndex }) =>
                                                arraySwap(items, activeIndex, overIndex).indexOf(id)
                                            }>
                                            <div className="align-right">
                                                {localData
                                                    .filter((a) => !a.hidden)
                                                    .map((item, index) => (
                                                        <SortableUser key={index} user={item} />
                                                    ))}
                                            </div>
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className="text-center mx-auto mt-5">
                                <Button onClick={saveAlertConfig}>Save Changes</Button>
                            </div>
                        </Row>
                    </>
                ) : (
                    <div className="text-center bg-light p-5 rounded">
                        <h4>your dashboard settings are managed by another user</h4>
                    </div>
                )}
            </Container>
        </>
    );
};

const ResetPasswordModal = ({ isOpen, toggle, user }) => {
    const [password, setPassword] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');

    // const [generatedPassword, setGeneratedPassword] = useState(null);

    // const generatePassword = (e) => {
    //     e.preventDefault()
    //     let randomString = Math.random().toString(36).slice(-8);
    //     navigator.clipboard.writeText(randomString);
    //     setGeneratedPassword(randomString)
    // }
    function preToggle() {
        setPassword('');
        setConfirmPassword('');
        toggle();
    }

    const [invalidState, setInvalidState] = useState({
        password: false,
    });

    function resetPassword() {
        if (!password) {
            return setInvalidState({
                password: !password,
            });
        }

        if (password.length < 4) return;

        if (confirmPassword !== password) return;

        // let savingPasswordToast = toast.loading('Resetting password...', {
        //     theme: 'light',
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        // });

        let payload = {
            email: user.email,
            password,
        };

        SrxApi.post('/edituserinfo', payload)
            .then((res) => {
                toast.success('User password updated');
                preToggle();
            })
            .catch((err) => {
                toast.error('Error: ' + err.message);
            });

        // SrxApi.post('/createuser', {
        //     ...newUser,
        //     faciID: facInfo.facID
        // })
        // .then(res => {
        //     toast.update(savingPasswordToast,
        //         {
        //             render: "Password successfully reset",
        //             type: "success",
        //             isLoading: false,
        //             autoClose: 3000,
        //             hideProgressBar: null,
        //             closeOnClick: null,
        //         });
        // })
        // .catch(err => {
        //     toast.update(savingPasswordToast,
        //         {
        //             render: err.response.status + ': ' + err.response.statusText,
        //             type: "error",
        //             isLoading: false,
        //             autoClose: 3000,
        //             hideProgressBar: null,
        //             closeOnClick: null,
        //         });
        // })
    }

    return (
        <Modal isOpen={isOpen} toggle={preToggle} size={'md'}>
            <ModalHeader toggle={preToggle}>Reset Password</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={9}>
                        <FormGroup>
                            <Label for="password">
                                New Password <small>*required</small>
                            </Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                className={`${
                                    (password && password.length < 4) || invalidState.password ? 'invalid' : ''
                                }`}
                                autoComplete="off"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => {
                                    if (e.target.value)
                                        setInvalidState({
                                            ...invalidState,
                                            password: false,
                                        });
                                    setPassword(e.target.value);
                                }}
                            />
                            {invalidState.password ? <div className="invalid-text">Password is required</div> : ''}
                            {password && password.length < 4 ? (
                                <div className="invalid-text">Password must be at least 4 characters long</div>
                            ) : (
                                ''
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={9}>
                        <FormGroup>
                            <Label for="confirm-password">
                                Confirm New Password <small>*required</small>
                            </Label>
                            <Input
                                type="password"
                                name="confirm-password"
                                id="confirm-password"
                                className={`${
                                    password && confirmPassword && password !== confirmPassword ? 'invalid' : ''
                                }`}
                                autoComplete="off"
                                placeholder="Confirm password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {password && confirmPassword && password !== confirmPassword ? (
                                <div className="invalid-text">Passwords do not match</div>
                            ) : (
                                ''
                            )}
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={preToggle}>
                    Cancel
                </Button>
                <Button color="primary" onClick={resetPassword}>
                    Save New Password
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const MediprocitySignUp = (props) => {
    const { user, setUser } = useContext(UserContext);

    const { toggle } = props;

    const [editCredentials, setEditCredentials] = useState(false);

    useEffect(() => {
        toggle('specialtyRxNow');
    }, []);

    useEffect(() => {
        if (!editCredentials) {
            setMediprocityCredentials({
                user: '',
                password: '',
                encryptionKey: '',
            });
        }
    }, [editCredentials]);

    const [mediprocityCredentials, setMediprocityCredentials] = useState({
        user: '',
        password: '',
        encryptionKey: '',
    });

    function saveMediprocityCredentials(e) {
        e.preventDefault(e);

        SrxApi.post('/loginmediprocity', mediprocityCredentials)
            .then((res) => {
                setUser({
                    ...user,
                    mediprocityUser: 1,
                    mediprocityInfo: res.data.mediprocityInfo,
                });
                setMediprocityCredentials({
                    user: '',
                    password: '',
                    encryptionKey: '',
                });
                setEditCredentials(false);
                toast.success('Credentials successfully saved');
            })
            .catch((err) => toast.error(err.response.data.message));
    }

    async function removeMediprocityCredentials() {
        if (
            (await confirm(
                'Remove SpecialtyRx Now credentials? This cannot be undone.',
                'Removing SpecialtyRx Now Credentials...',
                'Remove',
                'Cancel',
                'danger',
                'danger'
            )) === false
        )
            return;

        let removeMediprocityCredentialsToast = toast.loading('Removing SpecialtyRx Now credentials...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        SrxApi.post('/clearMediprocity')
            .then(({ data }) => {
                toast.update(removeMediprocityCredentialsToast, {
                    render: data.message,
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });

                setUser({
                    ...user,
                    mediprocityUser: 0,
                    mediprocityInfo: {},
                });
            })
            .catch((err) => {
                toast.update(removeMediprocityCredentialsToast, {
                    render: err.response.data.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
            });
    }

    return (
        <Container
            className="mt-5 mb-2 w-50 px-5 py-3 border border-light shadow-sm"
            style={{ borderRadius: '14px', minWidth: 'max-content', minHeight: '400px' }}>
            <h3>SpecialtyRx Now Credentials</h3>
            <hr />
            {!user.mediprocityUser || editCredentials ? (
                <form autoComplete="new-password" onSubmit={saveMediprocityCredentials}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="user">User</Label>
                                <Input
                                    type="text"
                                    name="user"
                                    id="user"
                                    required
                                    autoComplete="new-password"
                                    value={mediprocityCredentials.user}
                                    onChange={(e) =>
                                        setMediprocityCredentials({
                                            ...mediprocityCredentials,
                                            user: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    required
                                    autoComplete="off"
                                    value={mediprocityCredentials.password}
                                    onChange={(e) =>
                                        setMediprocityCredentials({
                                            ...mediprocityCredentials,
                                            password: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="encryptionKey">Encryption Key</Label>
                                <Input
                                    type="password"
                                    name="encryptionKey"
                                    id="encryptionKey"
                                    required
                                    autoComplete="off"
                                    value={mediprocityCredentials.encryptionKey}
                                    onChange={(e) =>
                                        setMediprocityCredentials({
                                            ...mediprocityCredentials,
                                            encryptionKey: e.target.value,
                                        })
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <CardFooter className="px-0">
                        <Row>
                            <Col className="d-flex justify-content-between">
                                {!!user.mediprocityUser && (
                                    <Button
                                        type="button"
                                        color="link"
                                        className="px-0"
                                        onClick={() => setEditCredentials(false)}>
                                        Cancel
                                    </Button>
                                )}
                                <Button type="submit" color="primary" className="ml-auto">
                                    Save Credentials
                                </Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </form>
            ) : (
                <div>
                    <Row>
                        <Col>
                            <Card className="text-white bg-success d-flex align-items-center">
                                <CardBody>
                                    <CardTitle tag="h3" className="mb-0 d-flex align-items-center">
                                        <span>SpecialtyRx Now Credentials Verified</span>
                                        <Icon icon={checkCircle} className="ml-2" />
                                    </CardTitle>
                                    {/* <CardText>
                                    With supporting text below as a natural lead-in to additional content.
                                </CardText>
                                <CardText>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a
                                    ante.
                                </CardText> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="link" className="px-0" onClick={removeMediprocityCredentials}>
                                Remove SpecialtyRx Now Credentials
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}
        </Container>
    );
};

export default Settings;
