import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    Card,
    CardBody,
    Table,
    Row,
    Col,
    Badge,
    Button,
} from 'reactstrap';
import { Icon } from '@iconify/react';
import accountIcon from '@iconify/icons-mdi/account';
import calendarMonth from '@iconify/icons-mdi/calendar-month';
import { UserContext } from '../contexts/UserContext';
import SimpleBar from 'simplebar-react';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import homeCity from '@iconify/icons-mdi/home-city';
import TableRowDetailModal from './TableRowDetailModal';
import noteMultipleOutline from '@iconify/icons-mdi/note-multiple-outline';   
import { useAtom } from 'jotai';
import { WidgetsData } from '../data/WidgetsData';
import { ActiveAlertContext } from '../contexts/ActiveAlertContext';
import 'flatpickr/dist/themes/dark.css';
import LastUpdated from '../utility-components/LastUpdatedComponent';
import { SingleWidgetActiveTabContext } from '../contexts/SingleWidgetActiveTabContext';
import useRefreshAlerts from '../custom-hooks/useRefreshAlerts';
import refreshIcon from '@iconify/icons-mdi/refresh';
import transferRight from '@iconify/icons-mdi/transfer-right';
import chatIcon from '@iconify/icons-mdi/chat-processing-outline';
import doctor from '@iconify/icons-mdi/doctor';
import medicineBottle from '@iconify/icons-mdi/medicine-bottle';
import { SrxApi } from '../api/SrxApi';
import { SelectedFacilitiesContext } from '../contexts/SelectedFacilitiesContext';
import transferLeft from '@iconify/icons-mdi/transfer-left';


const SingleAlertWidgetV2 = (props) => {
    const { selectedFacilities } = useContext(SelectedFacilitiesContext);

    const { activeAlert, setActiveAlert } = useContext(ActiveAlertContext);

    const { activeTab, setActiveTab } = useContext(SingleWidgetActiveTabContext);

    const [archiveMode, setArchiveMode] = useState(false);

    useEffect(() => {
        if (props.location.pathname.split('/').pop() === 'archive') setArchiveMode(true);
    }, []);

    // const [singleWidgetInfo, setSingleWidgetInfo] = useState(widgetInfo);

    const getActiveWidget = () => {
        const paths = props.location.pathname.split('/');

        const guess = paths.pop();

        return guess === 'archive' ? paths.pop() : guess;
    }

    var activeWidget = getActiveWidget();

    const widgetData = WidgetsData.find((widget) => widget.path.split('/').pop() === activeWidget);

    const [data, setData] = useAtom(widgetData.atom);

    // const [filteredAlerts, setFilteredAlerts] = useAtom(widgetData.atomFilteredAlerts);

    const [isModalOpen, setIsModalOpen] = useState(false);

    // const [activeTab, setActiveTab] = useState('current');

    const query = new URLSearchParams(props.location.search);

    useEffect(() => {
        setActiveTab('current');
    }, [props.location.search]);

    useEffect(() => {
        loadAlertsOnFilter();
    }, [selectedFacilities]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (activeAlert) setIsModalOpen(true);
        else setIsModalOpen(false);
    }, [activeAlert]);

    const firstRender = useRef(true);

    const toggleModal = () => {
        if (activeAlert) setActiveAlert(null);
        setIsModalOpen(!isModalOpen);
    };

    function getDateAndTime(date) {
        let onlyDate = new Date(date).toLocaleString();
        return onlyDate;
    }

    function getDate(date) {
        if (!date) return '';
        let onlyDate = new Date(date).toLocaleDateString();
        return onlyDate;
    }

    const patientNameCol = (data) => {
        return (
            <div className="col-truncate" title={data}>
                {data.toLowerCase()}
            </div>
        );
    };

    let singleWidgetInfoArray = [
             
        {
            name: 'Refill Too Soon',
            stateName: 'results',
            category: 'refillTooSoon',
            requestActionPermissionWidgetName: 'refillTooSoon',
            path: 'refilltoosoon',
            defaultSortField: '',
            active: true,
            refreshSchedule: {
                mins: 2,
                scnds: 40,
            },
            columns: [    
                {
                    name: 'Patient',
                    key: 'patientName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facilityName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                    className: 'faci-col',
                },
                {
                    name: 'Nursing Station',
                    key: 'nursingStationId',
                    sort: true,
                    icon: doctor,
                    prefix: '',
                    suffix: '',
                    format: '',
                    // className: 'faci-col',
                },
                {
                    name: 'Drug Name',
                    key: 'drugLabelName',
                    sort: true,
                    icon: medicineBottle,
                    prefix: '',
                    suffix: '',
                    format: '',
                    // className: 'faci-col',
                },
                {
                    name: 'Due Date',
                    key: 'dueOn',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                    // className: 'faci-col',
                },
                {
                    name: 'Alerted On',
                    key: 'receivedOn',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                    // className: 'faci-col',
                },
            ],
          
        },
        {
            name: 'Pump Pickup',
            stateName: 'results',
            category: 'pumpPickup',
            requestActionPermissionWidgetName: 'pumpPickup',
            path: 'pumpPickup',
            defaultSortField: '',
            active: true,
            refreshSchedule: {
                mins: 2,
                scnds: 40,
            },
            columns: [    
                {
                    name: 'Patient',
                    key: 'patientName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facilityName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                    className: 'faci-col',
                },
                {
                    name: 'Nursing Station',
                    key: 'nursingStationId',
                    sort: true,
                    icon: doctor,
                    prefix: '',
                    suffix: '',
                    format: '',
                    // className: 'faci-col',
                },
                {
                    name: 'Serial Number',
                    key: 'serialNumber',
                    sort: true,
                    icon: medicineBottle,
                    prefix: '',
                    suffix: '',
                    format: '',
                    className: 'faci-col',
                },
                {
                    name: 'Requested On',
                    key: 'receivedOn',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                    // className: 'faci-col',
                },
            ],
          
        },
        {
            name: 'NARCOTICS REFILL',
            stateName: 'results',
            category: 'narcoticsRefill',
            requestActionPermissionWidgetName: 'narcoticsRefill',
            path: 'narcoticsRefill',
            defaultSortField: '',
            active: true,
            refreshSchedule: {
                mins: 2,
                scnds: 40,
            },
            columns: [    
                {
                    name: 'Patient',
                    key: 'patientName',
                    sort: true,
                    icon: accountIcon,
                    prefix: '',
                    suffix: '',
                    format: patientNameCol,
                },
                {
                    name: 'Facility',
                    key: 'facilityName',
                    sort: true,
                    icon: homeCity,
                    prefix: '',
                    suffix: '',
                    format: '',
                    className: 'faci-col',
                },
                {
                    name: 'Nursing Station',
                    key: 'nursingStationId',
                    sort: true,
                    icon: doctor,
                    prefix: '',
                    suffix: '',
                    format: '',
                    // className: 'faci-col',
                },
                {
                    name: 'Drug Name',
                    key: 'drugLabelName',
                    sort: true,
                    icon: medicineBottle,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Prescriber',
                    key: 'physicianName',
                    sort: true,
                    icon: doctor,
                    prefix: '',
                    suffix: '',
                    format: '',
                },
                {
                    name: 'Alerted On',
                    key: 'createdAt',
                    sort: true,
                    icon: calendarMonth,
                    prefix: '',
                    suffix: '',
                    format: getDate,
                    className: 'faci-col',
                },
            ],
          
        },
    ];

    // this includes column display info. It will enevtually be combined with widgetData
    const widgetInfo = singleWidgetInfoArray.find((widget) => widget.path.toLowerCase() === activeWidget.toLowerCase());

    const [sortBy, setSortBy] = useState({
        sortField: widgetData.defaultSortField,
        sortDirection: 'asc',
    });

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        loadData(false, true, 1)
    }, [sortBy]);

    const onSort = (field) => {
        if (field === sortBy.sortField) {
            var sortDirection = sortBy.sortDirection === 'asc' ? 'desc' : 'asc';

            setSortBy({
                ...sortBy,
                sortDirection: sortDirection
            });
        } else {            
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            });
        }
    }

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ? (
                <Icon icon={arrowUp} color="black" />
            ) : (
                <Icon icon={arrowDown} color="black" />
            )}
        </>
    );

    const [rotateRefreshButton, setRotateRefreshButton] = useState(false);

    const { refreshWidget } = useRefreshAlerts();

    const loadAlertsOnFilter = async () => {
        loadData(false, false, 1);
    }

    const loadData = async (more = true, sort = false, pageNumber = null) => {
        pageNumber = pageNumber ?? data.nextPage;

        let url = getUrl();

        if(sort){
            console.log('hola', sort, data.isSorted, sortBy);
            url += `sort=${sortBy.sortField}&sortOrder=${sortBy.sortDirection}&page=${pageNumber}`;
        }else if (more) {
            url += `cursor=${data.cursor}`;
        }

        if (selectedFacilities) {
            selectedFacilities.forEach((facility) => {
                url += `&facilityIds=${facility.facID}`;
            });
        }

        const results = await SrxApi.get(url);
        const newData = results.data;

        const alertData = more ? [...data.alertData, ...newData.results] : newData.results;

        setData({
            ...data,
            alertData: alertData,
            cursor: newData.cursor,
            lastRefreshed: newData.timeStamp,
            loading: false,
            error: false,
            alertCount: newData.count,
            nextPage: newData.nextPage,
            isSorted: sort || data.isSorted,
        });
    }

    const getUrl = () => {
        let url = props.location.pathname.replace('/archive', '') + '?';

        if (archiveMode || props.location.pathname.split('/').pop() === 'archive') url += 'archived=true&';

        return url.replace('/alerts', '');
    }

    return (
        <>
            <Row className="mt-2 mb-1  justify-content-between">
                <Col className="d-flex align-items-center">
                    <h3>{widgetInfo.name}</h3>
                    <Button
                        color="secondary"
                        className={`ml-2 py-0 px-1 d-flex align-items-center`}
                        style={{ right: '20px' }}
                        onClick={() => {
                            setRotateRefreshButton(true);
                            refreshWidget(widgetInfo.path);
                        }}
                        title="Refresh Alerts">
                        <Icon
                            className={`${rotateRefreshButton && 'refresh-widget-button-rotate'}`}
                            onAnimationEnd={() => setRotateRefreshButton(false)}
                            icon={refreshIcon}
                            width="25"
                        />
                    </Button>
                </Col>
                <Col sm={'auto'}>
                    {!archiveMode ?
                        <Button
                            color="dark"
                            onClick={() => {
                                props.history.push(props.location.pathname + '/archive');
                            }}>
                            <span className="mr-1">
                                <b>Archive</b>
                            </span>
                            <Icon icon={transferRight} width="30" />
                        </Button>
                        :
                        <Button
                            color="dark"
                            onClick={() => {
                                props.history.push(props.location.pathname.replace('/archive', ''))
                            }}>
                            <Icon icon={transferLeft} width="30" />
                            <span className="ml-1">
                                <b>Current</b>
                            </span>
                        </Button>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card
                        style={{
                            minHeight: '410.61px',
                            opacity: widgetInfo.active ? '' : '.30',
                            pointerEvents: widgetInfo.active ? '' : 'none',
                        }}>
                        <div
                            className="d-flex justify-content-between mt-2 mx-3 align-items-center"
                            style={{ minHeight: '38px' }}>
                            <div className="d-flex">
                                <Badge className="py-1 px-2" color="light">
                                    <LastUpdated lastRefreshed={data.lastRefreshed} widgetPath={widgetInfo.path} />
                                </Badge>
                            </div>
                            <div style={{ fontSize: '16px' }}>
                                Total: <b>{data.alertCount}</b>
                            </div>
                        </div>
                        <SimpleBar className="single-widget-height mx-2 my-2">
                            <CardBody className="pb-1 px-1 pt-0">
                                <Table hover size="sm" className="mb-1">
                                    <thead style={{ position: 'sticky', top: '0' }} className="bg-light alert-th">
                                        <tr style={{ overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '15px' }}>
                                            {widgetInfo.columns.map((col) => (
                                                <th
                                                    className={`py-2 position-relative ${col.sort ? '' : 'noHover'}`}
                                                    onClick={col.sort ? () => onSort(col.key) : null}>
                                                    {col.icon && <Icon icon={col.icon} />} {col.name}{' '}
                                                    {col.sort ? (
                                                        sortBy.sortField === col.key ? (
                                                            <SortCarets />
                                                        ) : (
                                                            <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {data.loading ? (
                                                <td colSpan={widgetInfo.columns.length}>
                                                    <div>
                                                        <h5 className="text-center">Loading Alerts</h5>
                                                        <div className="bouncing-loader">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                </td>
                                            ) : (
                                                data.alertData
                                                    .map((alert, index) => (
                                                        <tr
                                                            key={alert.rxGuid}
                                                            style={{ fontSize: '13px' }}
                                                            onClick={() => {
                                                                setActiveAlert({
                                                                    name: widgetInfo.name,
                                                                    alert: alert,
                                                                    stateName: widgetInfo.stateName,
                                                                    category: widgetInfo.category,
                                                                    requestActionPermissionWidgetName: widgetInfo.requestActionPermissionWidgetName,
                                                                    tab: activeTab,
                                                                });
                                                            }}
                                                        >
                                                            {widgetInfo.columns.map((col) => (
                                                                <td
                                                                    style={{ verticalAlign: 'middle' }}
                                                                    className={col?.className}>
                                                                    {col.prefix}
                                                                    {col.format
                                                                        ? col.format(
                                                                            alert[col.key],
                                                                            alert.rxGuid,
                                                                            alert
                                                                        )
                                                                        : alert[col.key]}
                                                                    {col.suffix}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))
                                            )}
                                            <tr>
                                                <td colSpan="100%" align="center">
                                                    {data.alertData.length ?
                                                        data.alertData.length < data.alertCount ? <Button color="link"
                                                            onClick={() => loadData(true)}>
                                                            <b>Show more...</b>
                                                        </Button>
                                                            :
                                                            <span><b>End of alerts</b></span>
                                                        :
                                                        <span><b>No alerts</b></span>
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </SimpleBar>
                    </Card>
                    {activeAlert && (
                        <TableRowDetailModal isOpen={isModalOpen} toggle={toggleModal} alertInfo={activeAlert} />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default SingleAlertWidgetV2;
